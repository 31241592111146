$brand-01: #A07BF1;
$brand-02: #AFABF8;
$brand-03: #221E4C;
$text-01: #221E4C;
$text-02: #495057;
$text-03: #adb5bd;
$text-04: #ffffff;
$field-01: #f4f4f4;
$field-02: #ffffff;
$support-01: #dc3545;
$support-02: #198754;
$support-background-01: #ffe0e3;
$support-background-02: #C7EFCC;
$support-background-03: #E7E9F8;
$support-background-04: #E9C6B3;
$icon-01: #221E4C;
$icon-02: #ffffff;
$grey10: #f8f9fa;
$grey20: #f4f4f4;
$grey30: #e9ecef;
$grey40: #dee2e6;
$grey50: #ced4da;
$grey60: #adb5bd;
$grey70: #6c757d;
$grey80: #495057;
$grey90: #343a40;
$grey100: #1e1e1e;
$link-01: #A07BF1;
$link-01-hover: #8975E5;
$link-02: #221E4C;
$link-02-hover: #1e1e1e;
$link-03: #ffffff;
$button-01: #A07BF1;
$button-02: #AFABF8;
$button-03: #221E4C;
$button-01-hover: #8975E5;
$button-02-hover: #C3C0FA;
$button-03-hover: #1e1e1e;
$button-04-hover: #cc2525;
