@use "../../theme/customColours.scss" as custom;
.image-container {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  .skeleton {
    width: 100%;
    background-color: custom.$skeletonImageBase;
    overflow: hidden;
    position: absolute;
    top: 0;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
    aspect-ratio: 1/1;
    &.loading {
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            background: linear-gradient(90deg,custom.$skeletonImageBase,custom.$skeletonHighlightColor,custom.$skeletonImageBase);
            animation: flare 1.5s infinite cubic-bezier(0.25, 0.46, 0.45, 0.94);
            aspect-ratio: 1/1;
        }
        @keyframes flare {
            0% {
              left: -100%;
            }
            100% {
              left: 100%;
            }
          }
    }
    &.loaded{
        opacity: 0;
    }
  }
  
  .image {
    width: 100%;
    height: auto;
  }