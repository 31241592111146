@use "../../theme/colours.scss" as colours;
@import "../../theme/theme.scss";
label.custom-textarea-label{
    display: flex;
    flex-direction: column;
    gap: 8px;
    color: colours.$text-02;
    width: 100%;
}
.custom-textarea-container{
    textarea.custom-textarea{
        font-family: 'DM Sans';
        padding: 11px 10px 11px 16px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        background: colours.$field-02;
        border: none;
        width: calc(100% - 26px);
        z-index: 0;
        outline: 2px solid colours.$grey40;
        border-radius: map-get($theme, borderRadius, border-radius-medium);
        transition: outline .2s ease-in-out;
        color: colours.$text-01;
        resize: none;
        &:hover {
            outline: 2px solid colours.$brand-01;
        }
    }
    textarea:focus-visible {
        outline: 2px solid colours.$brand-01;
    }
    textarea::placeholder {
        color: colours.$text-03;
    }
    textarea:-ms-textarea-placeholder {
        color: colours.$text-03;
    }
    textarea::-ms-textarea-placeholder {
        color: colours.$text-03;
    }
    position: relative;

    .helper-text {
        margin-top: 6px;
        text-align: left;
    }
}