@use "../../theme/colours.scss" as colours;
@import "../../theme/theme.scss";
.custom-checkbox {
    all: unset;
    min-width: 14px;
    min-height: 14px;
    border: 1px solid map-get($theme,palette,ui-base,grey40);
    border-radius: 4px;
    cursor: pointer;
    position: relative;
    background-color: map-get($theme,palette,background,ui-background);
    transition: all 0.2s ease-in-out;
    &.checked {
        background-color: map-get($theme,palette,brand,brand-01);
        border: 1px solid map-get($theme,palette,brand,brand-01);
    }
    &:hover {
        border: 1px solid map-get($theme,palette,brand,brand-01);
    }
    svg {
        position: absolute;
        top: 2px;
        left: 2px;
    }
    .checkbox-tooltip{
        position: absolute;
        width: max-content;
        padding: 6px;
        border-radius: 6px;
        background-color: colours.$brand-03;
        pointer-events: none;
        opacity: 0;
        transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        transition-delay: 0.5s;
        left: 12px;
        top: 18px;
        z-index: 6;
        border-top-left-radius: unset;
        p {
            color: colours.$text-04;
        }
        &.show {
            opacity: 1;
        }
        &.hide {
            opacity: 0;
        }
    }
}