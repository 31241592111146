@use "../../theme/colours.scss" as colours;
@import "../../theme/theme.scss";
.custom-label{
    all: unset;
    display: flex;
    flex-direction: column;
    gap: 8px;
    color: colours.$text-02;
    width: 100%;
    position: relative;
}
.custom-multi-select-container{
    div.custom-multi-select{
        min-height: 18px;
        padding: 11px 10px 11px 16px;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        background: colours.$field-02;
        border: none;
        width: calc(100% - 26px);
        outline: 2px solid colours.$grey40;
        border-radius: map-get($theme, borderRadius, border-radius-medium);
        transition: outline .2s ease-in-out;
        color: colours.$text-01;
        position: relative;
        &:hover {
            outline: 2px solid colours.$brand-01;
        }
        .rendered-value {
            max-height: 18px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .multi-select-options{
            z-index: 3;
            position: absolute;
            width: 100%;
            padding: 0px;
            left: 0px;
            top: 43px;
            background: colours.$field-02;
            border-radius: map-get($theme, borderRadius, border-radius-medium);
            box-shadow: 0px 0px 4px colours.$grey60;
            transition: all 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
            overflow: hidden;
            opacity: 0;
            &.hide{
                pointer-events: none;
                height: 0px;
            }
            &.show{
                padding: 8px 0;
                pointer-events: all;
                height: 344px;
                opacity: 1;
            }
         }
    }
    div.select-type{
        padding: 11px 50px 11px 16px;
        width: calc(100% - 66px);
    }
    input:focus-visible {
        outline: 2px solid colours.$brand-01;
    }
    position: relative;

    .chevron-icon {
        position: absolute;
        pointer-events: none;
        color: colours.$icon-01;
        min-width: 20px;
        min-height: 20px;
        top: 10px;
        right: 10px;
        cursor: pointer;
        z-index: 1;
        transform: rotateY(0deg);
        transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
        &:hover {
            opacity: 0.7;
        }
        &.show-options {
            transform: rotate(180deg);
        }
    }
    .helper-text {
        margin-top: 6px;
    }
}