@use "../../theme/colours.scss" as colours;

.badge-container {
    transition: background-color .075s ease-in-out;
    &.default {
        background-color: colours.$brand-02;
    }
    &.error {
        background-color: colours.$support-background-01;
    }
    &.success {
        background-color: colours.$support-background-02;
    }
    &.info {
        background-color: colours.$support-background-03;
    }
    &.warning {
        background-color: colours.$support-background-04;
    }
    &.base{
        padding: 2px 12px;
        border-radius: 12px;
    }
    &.small{
        padding: 2px 10px;
        border-radius: 10px;
    }
}