@use "../../theme/colours.scss" as colours;
.modal-overlay {
    position: fixed;
    height: 100vh;
    width: 100vw;
    z-index: 99998;
    background-color: rgba(0,0,0,0.3);
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0px;
    left: 0px;
    pointer-events: all;
    .children-container {
        z-index: 99999;
        perspective: 600px;
        perspective-origin: bottom;
        transform: translate3d(0px, 30px, 0px);
        opacity: 0.5;
        max-width: calc(100vw - 32px);
    }
    &.hide {
        opacity: 0;
        background-color: rgba(0,0,0,0);
        pointer-events: none;
    }
    &.show {
        animation: fadein 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;

        .children-container {
            animation: showchild 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
        }
    }
    @keyframes fadein {
        0% {
            background-color: rgba(41, 59, 97, 0);
            pointer-events: none;
        }
        100% {
            background-color: rgba(41, 59, 97, 0.3);
            pointer-events: all;
        }
    }
    @keyframes showchild {
        0% {
            transform: translate3d(0px, 30px, 0px);
            opacity: 0.5;
        }
        100% {
            transform: translate3d(0px, 0px, 0px);
            opacity: 1;
        }
    }
}