@use "../../theme/breakpoints.scss" as breakpoints;
@use "../../theme/colours.scss" as colours;

.content-layout{
    background-color: colours.$grey10;
    min-height: calc(100vh - 432px);
    @include breakpoints.mobileSmall {
       width: calc(100% - 32px);
       padding: 105px 16px;
    }
    @include breakpoints.mobileMedium {
        width: calc(100% - 32px);
        padding: 105px 16px;
    }
    @include breakpoints.mobileLarge {
        width: calc(100% - 32px);
        padding: 105px 16px;
    }
    @include breakpoints.tablet {
        width: calc(100% - 64px);
        padding: 76px 32px;
    }
    @include breakpoints.desktopSmall {
        width: calc(100% - 64px);
        padding: 76px 32px;
    }
    @include breakpoints.desktopMedium {
        width: calc(100% - 64px);
        padding: 76px 32px;
    }
    @include breakpoints.desktopLarge {
        width: calc(100% - 64px);
        padding: 76px 32px;
    }
}