@use "../../theme/colours.scss" as colours;
@import "../../theme/theme.scss";

.pill-button {
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 100px;
  font-family: map-get($theme, typography, heading-compact-01, fontFamily);
  letter-spacing: map-get($theme, typography, heading-compact-01, letterSpacing);
  margin-top: map-get($theme, typography, heading-compact-01, marginTop);
  margin-bottom: map-get($theme, typography, heading-compact-01, marginBottom);
  margin-left: map-get($theme, typography, heading-compact-01, marginLeft);
  text-transform: map-get($theme, typography, heading-compact-01, textTransform);
  text-decoration: map-get($theme, typography, heading-compact-01, textDecoration);
  transition: all 0.2s ease-in-out;
  color: colours.$button-03;
  background-color: colours.$text-04;
  border: 1px solid colours.$button-03;
  padding: 9.5px 16px;
  font-weight: map-get($theme, typography, heading-compact-01, fontWeight);
  line-height: map-get($theme, typography, heading-compact-01, lineHeight);
  font-size: map-get($theme, typography, heading-compact-01, fontSize);
  text-align: center;
  &:disabled {
    cursor: not-allowed;
  }
  &:hover {
    color: colours.$button-03-hover;
    border: 1px solid colours.$button-03-hover;
  }
  &.selected {
    color: colours.$text-04;
    background-color: colours.$button-03;
    &:hover {
        color: colours.$text-04;
        background-color: colours.$button-03;
      }
  }
}

