@use "../../theme/breakpoints.scss" as breakpoints;
@use "../../theme/colours.scss" as colours;
@import "../../theme/theme.scss";

.custom-alert {
    display: none;
    padding: 12px 16px;
    align-items: center;
    border-radius: map-get($theme, borderRadius, border-radius-medium);
    &.success {
        background-color: colours.$support-background-02;
    }
    &.error {
        background-color: colours.$support-background-01;
    }
    &.info {
        background-color: colours.$support-background-03;
    }
    &.show {
        display: flex;
        .icon {
            max-height: 20px;
            svg {
                min-width: 20px;
                min-height: 20px;
                max-height: 20px;
                max-width: 20px;
                margin-right: 12px;
                &.success{
                    color: colours.$support-02;
                }
                &.error{
                    color: colours.$support-01;
                }
                &.info{
                    color: colours.$brand-01;
                }
            }
        }
        .text-content{
            .title {
                color: colours.$grey100;
                margin-bottom: 8px;
            }
            .content{
                color: colours.$grey100;
            }
        }
    }
    
}
.floating {
    width: calc(100vw - 32px);
    @include breakpoints.tablet {
        width: calc(100vw - 64px);
    }
    position: fixed;
    display: flex;
    justify-content: center;
    top: 150px;
    z-index: 99999999;
    opacity: 0;
    transform: translateY(30px);
    animation: show-alert 5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.1s 1 forwards;
    .custom-alert {
        display: none;
        padding: 12px 16px;
        max-width: 310px;
        align-items: center;
        border-radius: map-get($theme, borderRadius, border-radius-medium);
        &.success {
            background-color: colours.$support-background-02;
        }
        &.error {
            background-color: colours.$support-background-01;
        }
        &.info {
            background-color: colours.$support-background-03;
        }
        &.show {
            display: flex;
            .icon {
                max-height: 20px;
                svg {
                    min-width: 20px;
                    min-height: 20px;
                    max-height: 20px;
                    max-width: 20px;
                    margin-right: 12px;
                    &.success{
                        color: colours.$support-02;
                    }
                    &.error{
                        color: colours.$support-01;
                    }
                    &.info{
                        color: colours.$brand-01;
                    }
                }
            }
            .text-content{
                .title {
                    color: colours.$grey100;
                    margin-bottom: 8px;
                }
                .content{
                    color: colours.$grey100;
                }
            }
        }
        
    }
}
@keyframes show-alert {
    0% {
        opacity: 0;
        transform: translateY(30px);
    }
    10% {
        opacity: 1;
        transform: translateY(0px);
    }
    90% {
        opacity: 1;
        transform: translateY(0px);
    }
    100% {
        opacity: 0;
        transform: translateY(-30px);
    }
}