@import "../../theme/theme.scss";
.checkbox-container{
  display: flex;
  position: relative;
  cursor: pointer;
  .checkbox-content{
    margin-left: 26px;
    position: relative;
    .helper-text {
      position: absolute;
      margin-top: 6px;
    }
  }
  .checkspan{
    width: 16px;
    height: 16px;
    left: 0;
    position: absolute;
    border: 1px solid map-get($theme,palette,ui-base,grey40);
    border-radius: 4px;
    background-color: map-get($theme,palette,background,ui-background);
    &:after {
      content: "";
      position: absolute;
      display: none;
    }
  }
  .checkbox {
    &:checked + span {
      background-color: map-get($theme,palette,brand,brand-01);
      border: 1px solid map-get($theme,palette,brand,brand-01);
    }
    &:checked + span:after {
      display: block;
      left: 6px;
      top: 3px;
      width: 3px;
      height: 6px;
      border: 1px solid map-get($theme,palette,background,ui-background);
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
    &:hover + .checkspan {
      border: 1px solid map-get($theme,palette,brand,brand-01);
  }
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
}