@use "../../theme/colours.scss" as colours;
@use "../../theme/breakpoints.scss" as breakpoints;
.modal-overlay-for-image {
    position: fixed;
    height: 100vh;
    width: 100vw;
    z-index: 99998;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0px;
    left: 0px;
    pointer-events: all;
    .children-container {
        z-index: 99999;
        perspective: 600px;
        perspective-origin: bottom;
        transform: translate3d(0px, 30px, 0px);
        opacity: 0.5;
        max-width: calc(100vw - 32px);
        height: 400px;
        background-color: white;
        width: calc(100vw - 32px);;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        overflow-x: scroll;
        max-height: 700px;
        width: 100%;
        border-radius: 6px;
        height: auto;
        @include breakpoints.tablet {
            align-content: center;
        }
        .children{
            display: flex;
            justify-content: center;
            overflow-x: scroll;
            @include breakpoints.tablet {
                display: flex;
                justify-content: flex-start;;
            }
        }
        .title-container{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding: 10px;
            svg{
                width: 20px;
                height: 20px;
                cursor: pointer;
            }
        }
    }
    &.hide {
        opacity: 0;
        background-color: rgba(0,0,0,0);
        pointer-events: none;
    }
    &.show {
        animation: fadein 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;

        .children-container {
            animation: showchild 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
        }
    }
    @keyframes fadein {
        0% {
            background-color: rgba(41, 59, 97, 0);
            pointer-events: none;
        }
        100% {
            background-color: rgba(41, 59, 97, 0.3);
            pointer-events: all;
        }
    }
    @keyframes showchild {
        0% {
            transform: translate3d(0px, 30px, 0px);
            opacity: 0.5;
        }
        100% {
            transform: translate3d(0px, 0px, 0px);
            opacity: 1;
        }
    }
    .image-container{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
        gap: 16px;
        @include breakpoints.tablet {
            width: 35%;
            flex-direction: row;
        }
    }
    .image-container img {
        max-width: 250px;
        height: auto;
        border-radius: 6px;
        padding: 8px;
        box-sizing: border-box;
      }
}