.password-rules{
  display: flex;
  flex-direction: column;
}
.rule-container {
    display: flex;
    align-items: center;
    .rule-state {
        width:4px;
        height:4px;
        margin-right:8px;
        border-radius: 50%;
        min-width: 4px;
    }
}