@use "../../theme/colours.scss" as colours;
@import "../../theme/theme.scss";
.custom-link {
        all: unset;
        font-family: map-get($theme, typography, heading-compact-01, fontFamily);
        font-weight: map-get($theme, typography, heading-compact-01, fontWeight);
        line-height: map-get($theme, typography, heading-compact-01, lineHeight);
        font-size: map-get($theme, typography, heading-compact-01, fontSize);
        letter-spacing: map-get($theme, typography, heading-compact-01, letterSpacing);
        margin-top: map-get($theme, typography, heading-compact-01, marginTop);
        margin-bottom: map-get($theme, typography, heading-compact-01, marginBottom);
        margin-left: map-get($theme, typography, heading-compact-01, marginLeft);
        text-transform: map-get($theme, typography, heading-compact-01, textTransform);
        text-decoration: map-get($theme, typography, heading-compact-01, textDecoration);
        cursor: pointer;
    &.primary{
        color: colours.$link-01;
        &:hover {
            color: colours.$link-01-hover;
            text-decoration: underline;
        }
    }
    &.secondary{
        color: colours.$link-02;
        &:hover {
            color: colours.$link-02-hover;
            text-decoration: underline;
        }
    }
    &.light{
        color: colours.$link-03;
        &:hover {
            text-decoration: underline;
        }
    }
}