@use "../../theme/colours.scss" as colours;
.multi-select-option{
    all: unset;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    width: calc(100% - 32px);
    padding: 10px 16px;
    overflow: hidden;
    p{
        margin-left: 8px;
    }
    &:hover {
        background: colours.$grey30;
    }
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
}