@use "../../theme/colours.scss" as colours;
.select-option{
    all: unset;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    width: calc(100% - 32px);
    padding: 10px 16px;
    overflow: hidden;
    background: colours.$field-02;
    transition: all 0.2s ease-in-out;
    p{
        margin-left: 8px;
    }
    &:hover {
        background: colours.$grey30;
    }
    &.selected {
        background: colours.$brand-01;
        p{
            color: colours.$text-04;
        }
    }
}