@import "../../theme/theme.scss";
$properties: map-get($theme, typography);
$typography-variants: map-keys($properties);

@each $variant in $typography-variants {
    .#{$variant} {
        font-family: map-get($theme, typography, $variant, fontFamily);
        font-weight: map-get($theme, typography, $variant, fontWeight);
        line-height: map-get($theme, typography, $variant, lineHeight);
        font-size: map-get($theme, typography, $variant, fontSize);
        letter-spacing: map-get($theme, typography, $variant, letterSpacing);
        margin-top: map-get($theme, typography, $variant, marginTop);
        margin-bottom: map-get($theme, typography, $variant, marginBottom);
        margin-left: map-get($theme, typography, $variant, marginLeft);
        text-transform: map-get($theme, typography, $variant, textTransform);
        text-decoration: map-get($theme, typography, $variant, textDecoration);
        color: map-get($theme, palette, text, 'text-01');
    }
}
