@use "../../../theme/colours.scss" as colours;
@import "../../../theme/theme.scss";
label.custom-label{
    display: flex;
    flex-direction: column;
    gap: 8px;
    color: colours.$text-02;
    width: 100%;
}
.custom-input-container{
    input.custom-input{
        padding: 11px 10px 11px 16px;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        font-family: "DM Sans";
        background: colours.$field-02;
        border: none;
        width: calc(100% - 26px);
        z-index: 0;
        outline: 2px solid colours.$grey40;
        border-radius: map-get($theme, borderRadius, border-radius-medium);
        transition: outline .2s ease-in-out;
        color: colours.$text-01;
        &:hover {
            outline: 2px solid colours.$brand-01;
        }
    }
    input.password-type{
        padding: 11px 50px 11px 16px;
        width: calc(100% - 66px);
    }
    input:focus-visible {
        outline: 2px solid colours.$brand-01;
    }
    input::placeholder {
        color: colours.$text-03;
    }
    input:-ms-input-placeholder {
        color: colours.$text-03;
    }
    input::-ms-input-placeholder {
        color: colours.$text-03;
    }
    position: relative;

    .eye-icon-hidden {
        position: absolute;
        color: colours.$icon-01;
        min-width: 20px;
        min-height: 20px;
        top: 10px;
        right: 10px;
        cursor: pointer;
        z-index: 1;
        &:hover {
            opacity: 0.7;
        }
    }
    .helper-text {
        margin-top: 6px;
        text-align: left;
    }
}